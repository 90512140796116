import { keyframes, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const gradientMove = keyframes`
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
`;

export const AnimatedTypography = styled(Typography)(() => ({
  background: 'linear-gradient(90deg, #9F00C2AA, #9F00C2AA, #7518EBAA, #4B35FFAA, #4B35FFAA, #7518EBAA)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  color: 'transparent',
  backgroundSize: '200% 100%',
  animation: `${gradientMove} 3s linear infinite`,
}));
