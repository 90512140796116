import React, { useContext, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
  Collapse,
  Fade,
  Popover,
  Button,
  CircularProgress,
  Divider,
  Chip,
  PaperProps,
} from '@mui/material';
import { Add, DeleteOutlined, DragIndicator } from '@mui/icons-material';
import { UNTITLED_MODULE_TOPIC } from 'controllers/react-query/lessonSectionHooks';
import { useForm } from 'react-hook-form';
import { AnimatedTypography } from 'components/AnimatedTypography';
import { SortableItemContext } from 'components/SortableList/components/SortableItem';
import { Row } from 'components/Row/Row';
import { HookTextField } from 'components/HookForm/HookTextField';

type Props = {
  position: number;
  title: string;
  objectives: string;
  collapsed?: boolean;
  disableActions: boolean;
  isOverlayItem: boolean | undefined;
  addModule: (newModulePosition: number) => void;
  deleteModule: () => void;
};

export function CourseModuleCard({
  position,
  title,
  objectives,
  collapsed,
  disableActions,
  isOverlayItem,
  addModule,
  deleteModule,
}: Props) {
  const { attributes, listeners, ref, isDragging } = useContext(SortableItemContext);
  const [isCardHovered, setIsCardHovered] = useState(false);

  // These are override checks to show placeholders in UI if values for either title or objective is the initial, empty
  // value. `UNTITLED_MODULE_TOPIC` is being set by UI while `none` for objectives is set by the server when creating the module.
  const isUntitled = title.toLowerCase() === UNTITLED_MODULE_TOPIC.toLowerCase();
  const hasNoObjective = objectives === 'none';
  const { control } = useForm({
    defaultValues: { topic: isUntitled ? '' : title, objective: hasNoObjective ? '' : objectives },
  });

  const overlayItemProps: PaperProps = isOverlayItem ? { variant: 'elevation', elevation: 2 } : { variant: 'outlined' };

  return (
    <Stack width='100%' gap={0.5}>
      <Divider sx={{ width: '100%', opacity: 0, '&:hover': { opacity: 1 }, transition: 'opacity 0.2s ease' }}>
        <Chip
          label='Add module here'
          size='small'
          variant='outlined'
          icon={<Add />}
          onClick={() => addModule(position)}
          color='primary'
          component='button'
          disabled={disableActions}
        />
      </Divider>

      <Paper
        {...overlayItemProps}
        sx={{ bgcolor: 'background.default', borderRadius: 1, overflow: 'hidden', width: '100%' }}
      >
        <Box
          onMouseEnter={() => setIsCardHovered(true)}
          onMouseLeave={() => setIsCardHovered(false)}
          onFocusCapture={() => setIsCardHovered(true)}
          onBlurCapture={() => setIsCardHovered(false)}
          sx={{ display: 'grid', gridTemplateColumns: `56px 1fr`, gridTemplateRows: `1fr` }}
        >
          <Box
            {...attributes}
            {...listeners}
            ref={ref}
            sx={{
              display: 'grid',
              placeContent: 'center',
              bgcolor: `surfaceContainer.${isCardHovered || isDragging ? 'dark' : 'main'}`,
              color: 'text.secondary',
              position: 'relative',
              height: '100%',
              width: '100%',
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
          >
            <Fade in={!isCardHovered && !isDragging} appear={false} timeout={300}>
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Typography variant='labelLarge'>{position}</Typography>
              </Box>
            </Fade>
            <Fade in={isCardHovered || isDragging} timeout={300}>
              <DragIndicator />
            </Fade>
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 56px' }} p={2}>
            <Stack gap={1}>
              <HookTextField
                name='topic'
                control={control}
                rules={{ required: "Module topic can't be blank" }}
                size='small'
                fullWidth
                placeholder='Module title'
                sx={{ fontSize: t => t.typography.titleSmall }}
                data-testid='module-title'
                autoComplete='off'
              />
              <Collapse in={!collapsed} timeout='auto' unmountOnExit>
                <HookTextField
                  name='objective'
                  control={control}
                  rules={{ required: "Module objective can't be blank" }}
                  size='small'
                  multiline
                  fullWidth
                  value={objectives}
                  placeholder='Module objective'
                  sx={{ fontSize: t => t.typography.bodyMedium }}
                  autoComplete='off'
                />
              </Collapse>
            </Stack>
            <DeleteButtonWithConfirmation disableActions={disableActions} deleteModule={deleteModule} />
          </Box>
        </Box>
      </Paper>
    </Stack>
  );
}

function DeleteButtonWithConfirmation({
  disableActions,
  deleteModule,
}: Pick<Props, 'disableActions' | 'deleteModule'>) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        aria-describedby='delete-module-popover-button'
        data-testid='delete-module-button'
        disabled={disableActions}
        onClick={disableActions ? () => null : handleClick}
        size='small'
        style={{ alignSelf: 'start', justifySelf: 'end' }}
      >
        <DeleteOutlined fontSize='small' />
      </IconButton>
      <Popover
        id='delete-module-popover-button'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        slotProps={{ paper: { sx: { p: 1, borderRadius: 1.5, bgcolor: 'background.default' } } }}
        sx={{ ml: 3 }}
      >
        <Typography variant='titleSmall' pl={1}>
          Delete?
        </Typography>
        <Row justifyContent='end' gap={0.5} mt={0.5}>
          <Button onClick={handleClose} size='xs' variant='text' color='info'>
            Cancel
          </Button>
          <Button onClick={deleteModule} size='xs' variant='text'>
            {disableActions ? <CircularProgress color='info' size='1em' /> : 'Yes'}
          </Button>
        </Row>
      </Popover>
    </>
  );
}

const LoadingSkeleton = ({ position }: { position: number }) => (
  <Stack width='100%' gap={0.5}>
    <Box height='25px' />

    <Paper
      variant='outlined'
      sx={{ bgcolor: 'surfaceContainer.main', borderRadius: 1, overflow: 'hidden', width: '100%', height: 132 }}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: `56px 1px 1fr`, gridTemplateRows: `1fr`, height: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            placeContent: 'center',
            color: 'text.secondary',
            position: 'relative',
            height: '100%',
            width: '100%',
          }}
        >
          <Typography variant='labelLarge'>{position}</Typography>
        </Box>
        <Divider orientation='vertical' />
        <Stack justifyContent='center' alignItems='center' width='100%' height='100%'>
          <AnimatedTypography variant='titleMedium'>
            Generating a title and refining the module objectives.
          </AnimatedTypography>
        </Stack>
      </Box>
    </Paper>
  </Stack>
);

CourseModuleCard.LoadingSkeleton = LoadingSkeleton;
