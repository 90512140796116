import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import { DetailedSegmentType } from 'controllers/types';
import { useSnackbar } from 'notistack';
import { useAppendSectionAndGenerateSegments } from 'controllers/react-query/lessonSectionHooks';
import { KyronEvents } from '../../../utils/KyronEvents';
import { useUserContext } from '../../../UserContext';
import { SegmentTypeSelect } from './SegmentTypeSelect';
import { useEditorContext } from '../EditorContext';

export function AddSectionDialog({ onCreateSection, disabled }: { onCreateSection?: () => void; disabled?: boolean }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUserContext();
  const [topic, setTopic] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [segmentVariant, setSegmentVariant] = useState<DetailedSegmentType>('exploratory');
  const { mutateAsync, isPending, error } = useAppendSectionAndGenerateSegments();
  const navigate = useNavigate();
  const {
    editorState: { lessonId, activeSegmentId },
  } = useEditorContext();

  function handleSave() {
    if (!lessonId || !activeSegmentId) {
      enqueueSnackbar('Unable to create section: lessonId or currentSegmentID is missing', { variant: 'error' });
      return;
    }
    const payload = { lessonId, questionType: segmentVariant, lessonSection: { topic } };
    mutateAsync({ lessonId, activeSegmentId, payload }).then(newSection => {
      onCreateSection?.();
      navigate(`/studio/courses/${newSection.lesson_id}/segments/${newSection.segments[0].id}`);
      setIsDialogOpen(false);
    });
  }

  return (
    <>
      <Dialog open={isDialogOpen} fullWidth>
        <DialogTitle>Add Section</DialogTitle>
        <DialogContent>
          <Stack mt={2} gap={2}>
            <TextField
              data-testid='add-section-topic-name'
              label='Topic Name'
              value={topic}
              onChange={e => setTopic(e.target.value)}
              fullWidth
              autoFocus
              required
            />
            <Stack>
              <SegmentTypeSelect segmentType={segmentVariant} setSegmentType={setSegmentVariant} />
            </Stack>
          </Stack>
          {error && <Alert severity='error'>{`Unable to create section: ${error.message}`}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSave();
              KyronEvents.sendEvent(KyronEvents.names.ADD_SECTION, {
                lesson_id: Number(lessonId),
                user_id: user?.id || 'anonymous',
                variant: segmentVariant,
              });
            }}
            data-testid='confirm-add-section-button'
            disabled={!topic || isPending}
          >
            Add Section
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        startIcon={<Add />}
        sx={{ borderRadius: 2 }}
        variant='text'
        onClick={() => setIsDialogOpen(true)}
        disabled={disabled}
        data-testid='add-section-button'
      >
        Add section
      </Button>
    </>
  );
}
