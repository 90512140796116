import { useParams } from 'react-router-dom';
import { KyronID, LessonSection } from 'controllers/types';
import { useKyronMutationV2 } from './kyronMutation';
import { useKyronQuery } from './kyronQuery';

export const UNTITLED_MODULE_TOPIC = 'Untitled Module';

type CreateGeneratePayloadAndRequest = {
  lessonId: KyronID;
  activeSegmentId: KyronID;
  payload: {
    lessonId: KyronID | undefined;
    questionType: string;
    lessonSection: Partial<LessonSection>;
  };
};

// This hook will not only append a new section to the lesson, but also generate segments for the new section
// according to the "questionType" specified in the payload
export const useAppendSectionAndGenerateSegments = () =>
  useKyronMutationV2<CreateGeneratePayloadAndRequest, LessonSection>(`/lessons/:lessonId/lesson_sections`, {
    method: 'POST',
    invalidatesMultiple: ({ lessonId, activeSegmentId }) => [
      [`/lessons/${lessonId}/lesson_sections`],
      [`/lesson_segments/${activeSegmentId}`], // invalidate the currently-selected segment
    ],
  });

// This hook will "insert" section to an arbitrary position in lesson.lesson section per the "position" param passed in
// and, it will trigger the generation of BBD 1st stage outputs for the new section
// Request will NOT create a background task and resolve when generation is done.
export const useInsertSection = () =>
  useKyronMutationV2<
    { lessonId: KyronID; payload: { objective: string; lessonSection: { position: number } } },
    LessonSection
  >(`/lessons/:lessonId/lesson_sections`, {
    method: 'POST',
    invalidates: ({ lessonId }) => [`/lessons/${lessonId}/lesson_sections`],
  });

export const useUpdateSection = () => {
  const { segmentId: currentSegmentId } = useParams<{ segmentId: string }>();

  return useKyronMutationV2(`/lesson_sections/:lessonSectionId`, {
    method: 'PUT',
    invalidatesMultiple: ({ lessonSectionId, lessonId }) => [
      [`/lessons/${lessonId}/lesson_sections`],
      [`/lessons/${lessonId}`],
      [`/lesson_sections/${lessonSectionId}`],
      ['/lesson_segments', currentSegmentId], // invalidate the currently-selected segment
    ],
  });
};

// Used for repositioning either sections or segments
// Difference of using this hook instead of useUpdateSection is what it invalidates
// This hook will invalidate all the segment data, while useUpdateSection will only invalidate the section data
export const useUpdateSectionOrSegmentPosition = () =>
  useKyronMutationV2(`/lesson_sections/:lessonSectionId`, {
    method: 'PUT',
    invalidatesMultiple: ({ lessonSectionId, lessonId }) => [
      [`/lessons/${lessonId}/lesson_sections`],
      [`/lessons/${lessonId}`],
      [`/lesson_sections/${lessonSectionId}`],
      // invalidate all segments as this update might have effect on more than one segment
      // i.e. Moving a segment will have effect on neighboring segments
      [`/lesson_segments`],
    ],
  });

export const useUpdateAllSections = () =>
  useKyronMutationV2(`/lessons/:lessonId/lesson_sections`, {
    method: 'PUT',
    invalidates: ({ lessonId }) => [`/lessons/${lessonId}/lesson_sections`],
  });

export const useDeleteSection = () =>
  useKyronMutationV2<
    { lessonSectionId: KyronID; lessonId: KyronID; activeSegmentId?: KyronID },
    { lesson_sections: LessonSection[] },
    Error
  >(`/lessons/:lessonId/lesson_sections/:lessonSectionId`, {
    method: 'DELETE',
    invalidatesMultiple: ({ lessonSectionId, activeSegmentId, lessonId }) => [
      [`/lessons/${lessonId}/lesson_sections`],
      [`/lesson_sections/${lessonSectionId}`],
      [`/lesson_segments/${activeSegmentId}`], // invalidate the currently-selected segment (important in editor)
    ],
  });

export const useSectionQuery = (sectionId?: number | string) =>
  useKyronQuery<LessonSection>(`/lesson_sections/${sectionId}`, {
    queryKey: ['/lesson_sections', sectionId],
    enabled: !!sectionId,
    placeholderData: previousData => previousData,
  });
