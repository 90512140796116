import React from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useUpdateLessonAndLessonCollection } from 'controllers/react-query';
import { Lesson, LessonCreationStage } from 'controllers/types';
import { useSnackbar } from 'notistack';
import { ExamplePrompts } from 'components/MarketingHome/components/ExamplePrompts';
import { HookSelect } from '../../../../HookForm/HookSelect';
import { HookTextField } from '../../../../HookForm/HookTextField';
import { FormValues } from './formValues';
import { Footer } from '../../Components/MultiStepForm';

enum CourseLength {
  FIVE_MINUTES = 300,
  THIRTY_MINUTES = 1800,
  ONE_HOUR = 3600,
  TWO_HOURS = 3600 * 2,
  FOUR_HOURS = 3600 * 4,
}

export const courseLengths = [
  { label: '5 minutes - Great for single interactions', value: CourseLength.FIVE_MINUTES, disabled: true },
  { label: '30 minutes', value: CourseLength.THIRTY_MINUTES },
  { label: '1 hour', value: CourseLength.ONE_HOUR },
  { label: '2 hours', value: CourseLength.TWO_HOURS },
  { label: '4 hours', value: CourseLength.FOUR_HOURS },
];

type CourseOverviewFormProps = {
  lesson?: Lesson;
};
export const CourseOverviewForm = ({ lesson }: CourseOverviewFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, getValues, setValue } = useFormContext<FormValues>(); // gets the MultiStepForm context
  const { mutateAsync: updateLessonAndCollection, isPending } = useUpdateLessonAndLessonCollection({
    lessonId: lesson?.id,
    collectionId: lesson?.lesson_collection?.id,
  });

  return (
    <Stack sx={{ width: '100%' }} alignItems='center'>
      <Typography variant='headlineLarge' sx={{ mb: 3 }}>
        Course Overview
      </Typography>
      <Stack gap={4}>
        <ExamplePrompts
          onPromptClick={prompt => {
            setValue('courseOverview.name', prompt.lessonTitle);
            setValue('courseOverview.objective', prompt.lessonObjective);
            setValue('courseOverview.audience', prompt.audience);
          }}
          dense
        />
        <HookTextField
          control={control}
          name='courseOverview.name'
          topLabel='Course Title'
          topDescription='What is the title of this course? This will be displayed to learners.'
          placeholder='Where do bunnies live?'
          rules={{ required: 'Course title is required' }}
        />
        <HookSelect
          control={control}
          name='courseOverview.intended_time'
          topLabel='Course Duration'
          topDescription='How long should this course take to complete?'
        >
          {courseLengths.map(({ value, label, disabled }) => (
            <MenuItem key={value} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
        </HookSelect>
        <HookTextField
          control={control}
          name='courseOverview.objective'
          topLabel='Course Objectives'
          topDescription='What should students have learned by the end of this course?'
          placeholder='Students will be able to identify different types of bunny nests.'
          multiline
          maxRows={5}
          rules={{ required: 'Course objectives is required' }}
        />
        <HookTextField
          control={control}
          name='courseOverview.audience'
          topLabel='Audience'
          topDescription='Who is this lesson intended for? Are there any prerequisites or prior knowledge that a learner should be familiar with before attempting this lesson?'
          placeholder='College students starting their first semester.'
          multiline
          maxRows={5}
          rules={{ required: 'Course audience is required' }}
        />
      </Stack>
      <Footer
        nextButtonLabel='Generate course objectives'
        onNext={async goToNextStep =>
          updateLessonAndCollection({
            ...lesson,
            ...getValues().courseOverview,
            creation_stage: LessonCreationStage.overview,
            lesson_collection: {},
          })
            .then(goToNextStep)
            .catch(() => {
              enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            })
        }
        isPending={isPending}
      />
    </Stack>
  );
};
