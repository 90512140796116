import React from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { Stack, TextField, TextFieldProps } from '@mui/material';
import { KyronFormFieldLabel } from '../KyronFormFieldLabel';

// Add more ControllerProps in this list to have direct access to them in the HookSelect props
// rather than inside the otherControllerProps prop
type PickedControllerProps = 'name' | 'control' | 'rules';

export type HookTextFieldProps<T extends FieldValues = FieldValues> = {
  controllerProps?: Omit<ControllerProps<T>, PickedControllerProps | 'render'>;
  topLabel?: string;
  topDescription?: string;
  onCmdEnter?: () => void;
} & TextFieldProps &
  Pick<ControllerProps<T>, PickedControllerProps>;

/**
 * This is a TextField component from MUI v5 wrapped in a Controller component from react-hook-form
 * Ready to use with useForm hook!
 * example usage:
 * ```jsx
 * const { control } = useForm();
 *
 * <HookTextField
 *  name='lessonObjective'
 *  control={control}
 *  rules={{ required: 'This field is required' }}
 *  controllerProps={{ defaultValue: 'This is a lesson objective' }}
 *  label='Lesson objective'
 *  id='lesson-objective'
 *  multiline
 *  minRows={4}
 *  placeholder='Write a lesson objective here'
 * />
 * ```
 */
export function HookTextField<T extends FieldValues>({
  key,
  name,
  control,
  rules,
  controllerProps,
  topLabel,
  topDescription,
  onCmdEnter,
  ...textFieldProps
}: HookTextFieldProps<T>) {
  // Submit on command + enter
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.metaKey) {
      e.preventDefault();
      onCmdEnter?.();
    }
  };

  return (
    <Controller
      key={key}
      name={name}
      control={control}
      rules={rules}
      {...controllerProps}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Stack>
          <KyronFormFieldLabel
            label={topLabel}
            description={topDescription}
            htmlFor={textFieldProps.id}
            required={textFieldProps.required || Boolean(rules?.required)}
          />
          <TextField
            {...textFieldProps}
            variant='outlined'
            fullWidth
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error?.message || (onCmdEnter ? 'Cmd + Enter to submit' : undefined)}
            onKeyDown={handleKeyDown}
          />
        </Stack>
      )}
    />
  );
}
