import { EquationData } from 'components/Equation/types';

export type Misconception = {
  id: number;
  name: string;
  description: string;
  code: string;
  lesson_id: number;
};

export type MisconceptionPayload = Misconception;

export type KyronIntercomProp = {
  intercom_user_id: string;
  intercom_user_hash: string;
  intercom_api_base: string;
  intercom_app_id: string;
};

export type MainCredentials = {
  ga_id?: string;
  mixpanel_token?: string;
  intercom?: KyronIntercomProp | null;
  hubspot_portal_id?: string;
};

export type Organization = {
  id: number;
  name: string;
  slug: string;
  member_count: number;
  verified?: boolean;
  logo?: string;
  channel?: Channel;
  access_control: {
    can_create_course?: boolean;
    can_share_course?: boolean;
  };
};

export type OrganizationOverallAnalyticsType = {
  name: string;
  member_count: number;
  lesson_collections_count: number;
  lesson_instances_count: number;
  lesson_completions_count: number;
  completion_rate: number;
  unique_players: number;
  unique_lti_players: number;
  lesson_creators_count: number;
  lesson_names: Array<{ id: number; name: string }>;
};

export type OrganizationLessonAnalyticsType = {
  all_lesson_instances_by_date: Record<string, number>;
  all_lesson_completions_by_date: Record<string, number>;
  all_lesson_instances_by_hour: Record<string, number>;
};

export type StudentOverallAnalyticsType = {
  lessons_attempted: number;
  lessons_completed: number;
  lesson_completion_rate: number;
  unique_lessons_attempted: number;
  distinct_lesson_names: Array<{ id: number; name: string }>;
  distinct_lesson_names_completed: Array<{ id: number; name: string }>;
};

export type StudentLessonInstancesType = {
  all_lesson_instances: Array<{
    id: number;
    lesson_title: string;
    day_started: string;
    completed: boolean;
    misconception_count: number;
    misconception_instances: Array<{ id: number; name: string }>;
  }>;
};

export type LessonInstancesCard = {
  id: number;
  lesson_title: string;
  completed: boolean;
  day_started: string;
  misconception_count: number;
  misconception_instances: Array<{ id: number; name: string }>;
};

export type OrganizationPayload = {
  id?: number;
  name?: string;
  slug?: string;
  logo_image?: string;
  verified?: boolean;
  channel_attributes?: Partial<Channel>;
};

export type RoleName = 'admin' | 'org_admin' | 'teacher';

export type Role = {
  name: string;
  resource_id?: number;
};

export const RoleNameMap = {
  org_admin: 'Admin',
  teacher: 'Member',
};

export type Section = {
  lessonId: number;
  questionType: string;
  topic: string;
};

export type User = {
  avatar_url?: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  name: string;
  roles: Role[];
  tutor_id?: number;
  active_organization?: Organization;
  organizations?: Organization[];
  requires_subscription?: boolean;
};

export type TutorUser = Omit<User, 'roles'> & {
  active_organization_id: number;
  canonical_email: string;
};

export type Tutor = {
  id: number;
  description: string;
  display_name: string;
  picture?: string;
};
export type CorrectnessStatus = 'correct' | 'incorrect';

export type MultipleChoiceOption = {
  key: number;
  label: string;
  misconception: string;
  response: string;
  target_segment: LessonSegment | null;
  correctness_status: CorrectnessStatus | null;
};

export type EquationMultipleChoiceOption = { key: number; value: EquationData[] };

export type LessonSegment = {
  id: number;
  lesson_id: number;
  name: string;
  description: string;
  segment_type: SegmentType;
  label: DetailedSegmentType;
  start_time: number;
  end_time: number;
  next_segment: number;
  acceptable_inputs: string[];
  misconceptions: Misconception[];
  event_to_send: string;
  wait_timeout: number;
  video: string;
  dialogflow_page: string;
  question: string | null;
  answer: string | null;
  question_json?: EquationData[];
  prompt_selection: string;
  transcript: string;
  equation_display_input?: EquationData[];
  video_url: string | null;
  video_filename: string;
  video_duration: number;
  media_type: SegmentMedia | null;
  multiplechoices: MultipleChoiceOption[];
  equation_multiple_choices: EquationMultipleChoiceOption[];
  image: string;
  image_url: string;
  image_signed_id: string;
  answer_warning: string;
  duration: number;
  duration_completed: number;
  topic: string;
  video_vtt_url: string | null;
  video_status: VideoStatus | null;
};

export enum VideoStatus {
  MODERATION_ERROR = 'moderation_error',
  UNKNOWN_ERROR = 'unknown_error',
}

export enum SegmentMedia {
  KYRON_VIDEO = 'kyron_video',
  USER_VIDEO = 'user_video',
  USER_IMAGE_WITH_AUDIO = 'user_image_with_audio',
  USER_IMAGE_NO_AVATAR = 'user_image_no_avatar',
}

export type Edge = {
  id: number;
  target_segment_name: string;
  answer: string;
  answer_json: EquationData[];
  training_phrases: string[];
  position: number;
};

export type Pagination = {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
};

export type QueryPaginationProps = {
  page?: number;
  perPage?: number;
};

export type LessonValidation = {
  name: string;
  pass: boolean;
  message?: string;
};

export type ClassroomMisconceptionReport = {
  code: string;
  name: string;
  description: string;
  students: Array<{
    user_id: number;
    user_name: string;
    frequency: number;
  }>;
};

export type StudentsInClassroom = {
  student: User;
  last_active: string;
  lessons_attempted: number;
  lessons_with_misconceptions: number;
};

export type StudentWork = {
  lesson_instance_id: number;
  student: string;
  status: string;
  started_at: string;
  finished_at: string;
  has_sensitive_input: boolean;
  misconceptions: Misconception[];
  source: string;
  external_user_id?: string;
  assessment_score: number | null;
  avatar_url?: string;
};

type StepSegmentTranscript = {
  student_jumped: boolean;
  teacher_instructions: string;
};

export type OpenEndedMessage = {
  message: string;
  speaker: string;
};

type WaitSegmentTranscript = {
  student_jumped: boolean;
  question: string;
  student_response: {
    input_type: string;
    messages: string[] | OpenEndedMessage[];
  };
};

export type Transcript = {
  created_at: string;
  updated_at: string;
  completed_at: string;
  transcript: (StepSegmentTranscript | WaitSegmentTranscript)[];
};

export type ChatMessage = {
  id: number;
  role: string;
  content: string;
};

export type AiChatMessageOptions = {
  messages: string[];
  id: number;
};

export type LessonSegmentInstance = {
  id: number;
  lesson_instance_id: number;
  lesson_segment_id: number;
  student_text_input: string | null;
  created_at: string;
  updated_at: string;
  confidence: number;
  input_type: string;
  match_confidence: number;
  match_type: string;
  lesson_segment: LessonSegment;
};

export enum ViewType {
  LEARNER = 'LEARNER',
  KYRON_USER = 'KYRON_USER',
  PREVIEW = 'PREVIEW',
}

export type LessonInstance = {
  id: number;
  session_id: string;
  user_id: number;
  lesson: {
    guid: string;
    name: string;
  };
  lesson_id: number;
  created_at: string;
  updated_at: string;
  assessment_score?: number;
  view_type?: ViewType;
  lesson_segment_instances: LessonSegmentInstance[];
};

export type StudentResponseRow = {
  question: {
    question: string;
    segment_id: number;
    lesson_id: number;
  };
  student_input: {
    text: string;
    chat: ChatMessage[];
    sensitive_reason: string | null;
  };
};

export type LessonInstanceResponse = {
  lesson_segment: LessonSegment;
  lesson_segment_instance: LessonSegmentInstance;
  lesson_instance: LessonInstance;
  lesson: Lesson;
  table_of_contents: TOCItem[];
  chat_instance_id: number;
};

export type GotoLessonInstanceResponse = {
  lesson_segment: LessonSegment;
  lesson_segment_instance: LessonSegmentInstance;
  chat_instance_id: number;
  completed: boolean;
};

// status needs to align with model at background_task.rb
export type BackgroundTask = {
  id: number;
  name: string;
  message: string;
  status: 'enqueued' | 'started' | 'waiting' | 'done' | 'archived' | 'error';
  task_entity?: LessonSegment | Lesson | LessonInstance;
  task_entity_id?: number;
  task_entity_type: string;
  broadcast: boolean;
  job_id: string;
};

export type LessonContainerPayload = {
  resource_link: string;
  children?: LessonContainer[];
  children_count: number;
  description: string;
  container_thumbnail: string;
  id: number;
  lessons?: Lesson[]; // TODO (Harish) Remove after moving to lessonContainers.
  lesson_collections?: LessonCollection[];
  lesson_collection_count: number;
  name: string;
  parent_id: number;
  position: number;
  tier: number;
};

type UpdatedAt = {
  updated_at: string;
};

export type ChatMessagePayload = {
  user_comment: string | null;
  chat_instance_id: number;
  chat_message_id: number | null;
  selected_message_option: string | null;
};

export type LessonContainer = LessonContainerPayload & UpdatedAt;

export type BaseLessonCollection = {
  id: number;
  name: string;
  parent_id: number;
  custom_thumbnail?: string;
  lesson_instances?: LessonInstance[];
  published_at?: string;
  desired_languages?: string[];
  duration?: number;
  description: string;
};

export type LessonCollectionPayload = {
  description: string;
  position: number;
  lessons?: MinimalLesson[];
  draft_lessons?: MinimalLesson[];
  collection_thumbnail: string;
  resource_link: string;
  slug: string;
  channel?: Channel;
  visibility?: string;
  desired_languages?: string[];
  allow_anonymous_learners?: boolean;
};

export type LessonCollection = BaseLessonCollection & LessonCollectionPayload & UpdatedAt;

export type Assignment = {
  id: number;
  name: string;
  unit: string;
  unit_id?: number;
  course: string;
  time_since_assigned: number;
  students_attempted: number;
  students_with_misconceptions: number;
  thumbnail?: string;
};

export type GoogleClassroomAssignmentPayload = {
  classroomId: number;
  externalClassroomId: string;
  lessonId: number;
  draft: boolean;
};

export type AuxContentProcessReport = {
  request_id: string;
  message: string;
  aux_content_count: number;
  truncated_aux_content_count: number;
  processed_at: number;
  total_prompt_token_count: number;
  total_available_token_count: number;
  available_token_for_each_aux_content: number;
  aux_content_token_count: number[];
};

export type AuxContent = {
  id: number;
  filename: string;
  byte_size: number;
  process_reports: AuxContentProcessReport[];
  url: string;
};

export enum LessonCreationStage {
  overview = 'overview',
  module_outline = 'module_outline',
  editor = 'editor',
}

export type MinimalLesson = UpdatedAt & {
  id: number;
  name: string;
  audience: string | null;
  locale: 'es' | 'en';
  tutor?: Tutor;
  lesson_collection: Partial<BaseLessonCollection>;
  use_df: boolean;
  has_unpublished_changes: boolean;
  tutor_id: number;
  video_thumbnail: string;
  creation_stage: LessonCreationStage;
};

export type BasicLessonPayload = {
  lessonTitle: string;
  audience: string;
  lessonObjective: string;
};

export type LessonPayload = MinimalLesson & {
  description: string;
  objective: string | null;
  guid: string;
  nlp_type: string;
  gcp_project: string;
  gcp_df_agent: string;
  active: boolean;
  video_duration: number;
  video_url: string;
  editors: User[];
  finalized_at: string;
  video_updated_at: string;
  published_lesson_id: number;
  published_lesson_collection?: LessonCollection;
  table_of_contents?: TOCItem[];
  sections?: LessonSection[];
  requires_finalization: boolean;
  organization: Organization;
  has_misconceptions: boolean;
  tts_voice_id: string;
  aux_contents: AuxContent[];
  structured_learning_objectives: string[];
  essential_questions: string[];
  enduring_understandings: string[];
  total_time: number;
  intended_time?: number | null;
  custom_video_background_image_url?: string | null;
  has_canvas_course_link: boolean;
};

export type Lesson = LessonPayload;

export type TOCItem = {
  id: number;
  lesson_id: number;
  lesson_segment_id: number;
  position: number;
  label: string;
};
export type LessonDetails = {
  lesson_segment_names: string[];
  lesson_segment_names_ids: [number, string][];
  table_of_contents: TOCItem[];
};

export type LessonSection = {
  topic: string;
  position: number;
  id: KyronID;
  explanation?: LessonSegment;
  question?: LessonSegment;
  wait?: LessonSegment;
  isLoading?: boolean;
  segments: MinimalSegment[];
  lesson_id: KyronID;
  structured_learning_objectives?: string[] | null;
  intended_time?: number | null;
};

export type ModuleUnderConstruction = { id: string; under_construction: boolean; position: number };

export type SegmentType = 'step' | 'question' | 'wait';

export type DetailedSegmentType =
  | SegmentType
  | 'multiple_choice'
  | 'multiple_choice_assessment'
  | 'conversation'
  | 'exploratory'
  | 'assessment'
  | 'reflection'
  | 'formative';

export type MinimalSegment = {
  id: number;
  segment_type: SegmentType;
  acceptable_inputs: string[];
  label: DetailedSegmentType;
};

export type NextLessonSegmentResponse = {
  lesson_segment_instance: LessonSegmentInstance;
  lesson_segment: LessonSegment;
  completed: boolean;
  score_bg_task?: BackgroundTask;
  chat_instance_id: number;
  no_input_retry: number;
};

export type NextLessonSegmentForm = {
  lesson_segment_instance_id: number;
  session_id?: number | null;
  audio?: string;
  text?: string;
  equation?: string;
  multiple_choice?: string;
  open_ended?: string;
  equation_multiple_choice?: string;
  no_input_retry?: number;
};

export type APIRespForList<TData, TMeta = Pagination> = TData & { meta: TMeta };

export type PaymentSession = {
  id: string;
  payment_status: string;
  amount_total: number;
  user_id: number;
  status: string;
  product: string;
};

export type InviteUserResponse = {
  message: string;
  user?: User;
};

export type Invitation = {
  id: number;
  email: string;
  organization_id: number;
  invited_at: string;
  accepted_at: string | null;
  user?: User;
};

export type Classroom = {
  id: number;
  name: string;
  external_classroom_provider: string;
  external_classroom_id: string;
};

export type Channel = {
  id: number;
  name: string;
  description?: string;
  image_url?: string;
  image?: string;
};

export enum PublishVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type ConversationItem = {
  role: string;
  text: string;
};

export type PlaylistItem = {
  id: number;
  order: number;
  lesson_collection: LessonCollection;
} & UpdatedAt;

export type Playlist = {
  id: number;
  name: string;
  description: string;
  playlist_items?: PlaylistItem[];
} & UpdatedAt;

export type FulfillmentStatus = 'fulfilled' | 'pending' | 'failed';

export type SubscriptionPayload = {
  number_of_seats: number;
  expires_at: string;
};

export type Subscription = {
  id: number;
  stripe_subscription_id: string;
  stripe_product_id: string;
  stripe_pricing_id: string;
  organization_id: number;
  stripe_customer_id: string;
  fulfillment_status: FulfillmentStatus;
} & SubscriptionPayload;

export type CanvasCourseListing = {
  id: string;
  name: string;
  course_code: string;
};

export type CanvasCourse = {
  modules: Array<{
    id: string;
    name: string;
  }>;
} & CanvasCourseListing;

export type CanvasInstance = {
  host: string | null;
  client_id: string | null;
  // The backend doesn't pass a secret to the frontend so the key may not be present. The field
  // will be present when an org admin updates the value in the UI.
  client_secret?: string;
};

export type MutationRespWithBT<T> = {
  resource: T;
  background_task_ids: number[];
};

export type KyronID = string | number;
