import React from 'react';
import { Stack, Typography, Container, Button } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { HookTextField } from 'components/HookForm/HookTextField';
import { useSnackbar } from 'notistack';
import { useGenerateEntireLesson } from 'controllers/react-query/lessonGenerationHooks';
import { useNavigate } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { useModal } from 'components/utils/ModalContext';
import { CourseGeneratingMessage } from 'components/Studio/Editor/CourseGeneratingMessage';

type FormValues = {
  activityTemplate: string;
  auxContentSignedIds: string[];
  lessonObjective: string;
  audience: string;
};

const defaultValues: FormValues = {
  activityTemplate: 'practice_problems',
  auxContentSignedIds: [],
  lessonObjective: '',
  audience: '',
};

export function PracticeProblemsBuilder() {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ defaultValues });
  const { isPending, mutate: createLesson } = useGenerateEntireLesson();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { openModal, closeModal } = useModal();

  const onSubmit: SubmitHandler<FormValues> = data => {
    const createLessonPayload = {
      lessonTitle: `Practice Problems: ${data.lessonObjective}`,
      lessonObjective: data.lessonObjective,
      audience: data.audience,
      activityTemplate: data.activityTemplate,
    };

    KyronEvents.sendEvent(KyronEvents.names.SUBMIT_PRACTICE_FORM, {
      lesson_name: createLessonPayload.lessonTitle,
      user_id: user?.id || 'anonymous',
    });

    createLesson(
      { payload: createLessonPayload },
      {
        onSuccess: lesson => {
          navigate(`/studio/courses/${lesson.id}`);
          openModal({
            id: 'practice-creating',
            title: 'Your practice is being created!',
            content: <CourseGeneratingMessage title={lesson.name} type='practice' />,
            action: <Button onClick={closeModal}>Got it</Button>,
          });
        },
        onError: error => {
          console.error('Failed to create activity', error);
          enqueueSnackbar(`Failed to create activity`, { variant: 'error' });
        },
      },
    );
  };

  return (
    <Container maxWidth='sm'>
      <Stack gap={10}>
        <Typography variant='titleLarge' alignSelf='center'>
          Create practice problems
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} data-testid='practice-problems-builder-form'>
          <Stack gap={10}>
            <Stack gap={2}>
              <Typography variant='bodyLarge' component='label' id='audience-label'>
                1. Who is this practice intented for?
              </Typography>
              <HookTextField
                name='audience'
                rules={{ required: 'This field is required' }}
                control={control}
                multiline
                minRows={2}
                maxRows={2}
                id='audience'
                placeholder='College freshmen in an Intro to Chemistry class'
              />
            </Stack>

            <Stack gap={2}>
              <Typography variant='bodyLarge' component='label' id='learning-objectives-label'>
                2. What should learners be able to do after completing this activity?
              </Typography>
              <HookTextField
                name='lessonObjective'
                rules={{ required: 'This field is required' }}
                control={control}
                multiline
                minRows={4}
                maxRows={4}
                id='learning-objectives'
                placeholder='Learners will be able to balance chemical equations with 2-3 molecules on each side.'
              />
            </Stack>

            <Button
              type='submit'
              variant='contained'
              disabled={!isValid || isPending}
              data-testid='create-practice-button'
              startIcon={isPending ? <LoadingIndicator /> : null}
              sx={{ width: 'fit-content', alignSelf: 'flex-end', mb: 4 }}
            >
              Create Practice Problems
            </Button>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
