import React, { useEffect, useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText, Stack, Typography } from '@mui/material';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { KyronID, Lesson, LessonCreationStage, LessonSection, ModuleUnderConstruction } from 'controllers/types';
import { useDeleteSection, useLessonSectionsQuery, useUpdateLessonAndLessonCollection } from 'controllers/react-query';
import {
  UNTITLED_MODULE_TOPIC,
  useInsertSection,
  useUpdateAllSections,
} from 'controllers/react-query/lessonSectionHooks';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { SortableList } from 'components/SortableList';
import { Row } from 'components/Row/Row';
import { getProgressAndFailureFlags, useLessonBTWatcher } from 'components/BackgroundTasks/backgroundTaskWatchers';
import { useForm } from 'react-hook-form';
import { Footer } from '../../../Components/MultiStepForm';
import { useMultiStepFormContext } from '../../../Components/MultiStepForm/context';
import { CourseModuleCard } from '../../../Components/CourseModuleCard';
import {
  Props as FormContentPlaceholderProps,
  FormContentPlaceholder,
} from '../../HelperComponents/FormContentPlaceholder';
import { useHandleCollapse } from './helpers/useHandleCollapse';
import { CategorizedTasks } from '../../../../../../channels/UserNotifications';
import { useModal } from '../../../../../utils/ModalContext';
import { HookTextField } from '../../../../../HookForm/HookTextField';

type CourseModulesFormProps = {
  lesson?: Lesson;
};
export function CourseModulesForm({ lesson }: CourseModulesFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { lessonId } = useMultiStepFormContext();

  const { data, isLoading, isFetching, isRefetching, isError } = useLessonSectionsQuery(lessonId);
  const { active, failed, ...btStatus } = useLessonBTWatcher(lesson?.id);

  const { mutateAsync, isPending: isDeletePending } = useDeleteSection();
  const { mutateAsync: updateModules } = useUpdateAllSections();
  const { mutateAsync: createSection, isPending: isCreationPending } = useInsertSection();
  const { mutateAsync: updateLessonAndCollection, isPending: isUpdatePending } = useUpdateLessonAndLessonCollection({
    lessonId: lesson?.id,
    collectionId: lesson?.lesson_collection?.id,
  });

  // ANTI-PATTERN: Storing data in local state. Here is why:
  // To retain a smoother UI experience with sortable lists, we need to keep data in UI state rather than using
  // react-query's data directly. This way we can do optimistic updates to the UI state when module order is changed
  // and prevent unnecessary re-renders, and unexpected sortable list behavior.
  const [lessonModules, setLessonModules] = useState<(LessonSection | ModuleUnderConstruction)[]>([]);

  // To retain a smoother UI experience with sortable lists, we need to keep data in UI state
  useEffect(updateUIStateWithServerState, [data?.lesson_sections]);
  function updateUIStateWithServerState() {
    if (data?.lesson_sections) {
      setLessonModules(data.lesson_sections);
    }
  }

  const areTherePendingActions =
    isCreationPending || isUpdatePending || isDeletePending || btStatus.isModuleGenerationInProgress;

  const deleteModule = (moduleId: KyronID) => {
    if (!lessonId) return;
    mutateAsync({ lessonSectionId: moduleId, lessonId }).catch(() => {
      enqueueSnackbar('Failed to delete the module.', { variant: 'error' });
    });
  };

  const { collapseAll, allCollapsed, expandAll, allExpanded, collapsedModules } = useHandleCollapse(
    data?.lesson_sections || [],
  );

  // Update module positions will update the UI state optimistically first. Then, depending on the server's response,
  // it will either keep the optimistic update or revert back to the previous state.
  const updateModulePositions = (repositionedList: LessonSection[]) => {
    if (!lessonId) throw new Error('Lesson ID is missing');

    // update the value of each section according to new array index
    const updated: LessonSection[] = repositionedList.map((section, index) => ({ ...section, position: index + 1 }));

    const previousState = lessonModules;
    // optimistic update here - data will be reset to previous state if the mutation fails
    setLessonModules(updated);

    updateModules({ lessonId, payload: { lessonSections: updated } }).catch(() => {
      setLessonModules(previousState);
      enqueueSnackbar('Failed to reposition sections.', { variant: 'error' });
    });
  };

  // Add loading card to the list by optimistically updating the list.
  function addLoadingCard(position: number) {
    const insertionIndex = position - 1;
    setLessonModules(p => {
      const before = p.slice(0, insertionIndex);
      const after = p
        .slice(insertionIndex, p.length)
        // update all card positions after the insertion optimistically
        .map(module => ({ ...module, position: module.position + 1 }));
      const loadingCard: ModuleUnderConstruction = {
        id: 'module-under-construction',
        under_construction: true,
        position,
      };
      return [...before, loadingCard, ...after];
    });
  }

  const { openModal, closeModal } = useModal();

  const handleAddModule = (newModulePosition: number) => {
    if (!lessonId) {
      enqueueSnackbar('Lesson ID is missing.', { variant: 'error' });
      return;
    }

    if (!newModulePosition) {
      enqueueSnackbar('Invalid new module position.', { variant: 'error' });
      return;
    }

    openModal({
      id: 'addNewModule',
      title: 'Add a new module',
      content: (
        <AddNewModuleDialog
          onSubmit={async ({ objective }) => {
            try {
              const payload = {
                objective: objective.trim(),
                lessonSection: { topic: UNTITLED_MODULE_TOPIC, position: newModulePosition },
              };
              addLoadingCard(newModulePosition);
              closeModal();
              await createSection({ lessonId, payload });
            } catch (e) {
              // revert the list to server state
              setLessonModules(data?.lesson_sections || []);
              enqueueSnackbar(`Failed to add a new module: ${e}`, { variant: 'error' });
            }
          }}
        />
      ),
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='headlineLarge'>Course Modules</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        This is a module outline of your course. Each module will ultimately be roughly 30 minutes long and consist of
        lecture videos, multiple choice questions, discussion activities and a short module assessment. For now, verify
        the module titles and objectives below. Feel free to edit, add, delete or move the modules.
      </Typography>

      <Row gap={2} justifyContent='space-between' width='100%'>
        <Box>{isRefetching ? <LoadingIndicator message='Updating the data...' textVariant='bodyMedium' /> : null}</Box>
        <Row gap={2} justifyContent='flex-end' justifySelf='flex-end'>
          <Button
            size='small'
            variant='outlined'
            onClick={collapseAll}
            startIcon={<UnfoldLess />}
            disabled={allCollapsed || isFetching}
          >
            Collapse All
          </Button>
          <Button
            size='small'
            variant='outlined'
            onClick={expandAll}
            startIcon={<UnfoldMore />}
            disabled={allExpanded || isFetching}
          >
            Expand All
          </Button>
        </Row>
      </Row>

      {getStatusMessage(btStatus, failed, active, lessonModules, isLoading, isError)}

      <SortableList
        items={lessonModules}
        onChange={updateModulePositions}
        renderItem={(module, opts) => (
          <SortableList.Item id={module.id} disableSorting={areTherePendingActions}>
            {'under_construction' in module ? (
              <CourseModuleCard.LoadingSkeleton position={module.position} />
            ) : (
              <CourseModuleCard
                position={module.position}
                title={module.topic}
                objectives={module.structured_learning_objectives?.[0] || ''}
                collapsed={collapsedModules.includes(module.id)}
                addModule={handleAddModule}
                deleteModule={() => deleteModule(module.id)}
                disableActions={areTherePendingActions}
                isOverlayItem={opts?.isOverlay}
              />
            )}
          </SortableList.Item>
        )}
      />

      <Stack sx={{ width: '100%', alignItems: 'center', my: 2 }}>
        <Button
          size='small'
          variant='outlined'
          onClick={() => handleAddModule(lessonModules.length + 1)}
          disabled={areTherePendingActions}
        >
          Add Module
        </Button>
      </Stack>

      <Footer
        onNext={async () => {
          await updateLessonAndCollection({
            ...lesson,
            creation_stage: LessonCreationStage.module_outline,
            lesson_collection: {},
          });
          navigate(`/studio/courses/${lesson?.id}`);
        }}
        isPending={areTherePendingActions}
        disableNextButton={isError || isFetching || isLoading || lessonModules.length === 0}
      />
    </Box>
  );
}

export function getStatusMessage(
  btStatus: ReturnType<typeof getProgressAndFailureFlags>,
  failed: CategorizedTasks,
  active: CategorizedTasks,
  lessonModules: (LessonSection | ModuleUnderConstruction)[],
  isLoading: boolean,
  isError: boolean,
) {
  const renderPlaceholder = (variant: FormContentPlaceholderProps['variant'], message: string) => (
    <Box mt={2}>
      <FormContentPlaceholder variant={variant} message={message} />
    </Box>
  );

  if (isLoading) {
    return renderPlaceholder('progress', 'Loading modules...');
  }

  if (isError) {
    return renderPlaceholder('error', 'Could not load the modules.');
  }

  if (btStatus.isModuleGenerationInProgress) {
    return renderPlaceholder('progress', active.moduleGeneration[0].message);
  }

  if (btStatus.isModuleGenerationFailed) {
    return renderPlaceholder('error', failed.moduleGeneration[0].message);
  }

  if (!lessonModules.length) {
    return renderPlaceholder('info', 'No modules found.');
  }

  return null;
}

function AddNewModuleDialog({ onSubmit }: { onSubmit: (data: { objective: string }) => void }) {
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues: { objective: '' } });

  return (
    <>
      <DialogContent>
        <DialogContentText mb={1}>
          Add a new module to your course. Tell us what you want the module to be about and we’ll generate a title and
          refine your objective.
        </DialogContentText>

        <HookTextField
          multiline
          maxRows={4}
          control={control}
          name='objective'
          rules={{ required: 'This field is required.' }}
          placeholder='What should the new module be about?'
          autoComplete='off'
          autoFocus
          onCmdEnter={handleSubmit(onSubmit)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={closeModal}>
          Cancel
        </Button>
        <Button variant='text' onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
}
