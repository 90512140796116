import React, { createContext, useContext, useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconButton, ListItem, SxProps } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';

type Props = { id: UniqueIdentifier; disableSorting?: boolean };

type Context = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
  isDragging: boolean;
};

export const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
  isDragging: false,
});

export function SortableItem({ children, id, disableSorting }: PropsWithChildren<Props>) {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
    disabled: disableSorting,
  });
  const context = useMemo(
    () => ({ attributes, listeners, ref: setActivatorNodeRef, isDragging }),
    [attributes, listeners, setActivatorNodeRef, isDragging],
  );
  const style: CSSProperties = {
    marginBottom: '3px',
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <ListItem ref={setNodeRef} style={style}>
        {children}
      </ListItem>
    </SortableItemContext.Provider>
  );
}

export function DragHandle({ disableRipple, sx }: { disableRipple?: boolean; sx?: SxProps }) {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <IconButton {...attributes} {...listeners} ref={ref} size='small' sx={sx} disableRipple={disableRipple}>
      <DragIndicator fontSize='small' />
    </IconButton>
  );
}
